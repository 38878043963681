* {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

html,
body {
  margin: 0;
  overflow-x: hidden;
}

#root {
  z-index: 1;
}

#modal-root {
  z-index: 1000;
}
